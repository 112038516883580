@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
.footer-row {
  position: fixed;
  bottom: 0;
  background-color: #f6f6f6;
  width: 100%;
  z-index: 1;
  height: 10vh;
  margin: 0;
}
.footer-row .icon-row, .footer-row .text-row {
  width: 100%;
  margin: 0;
}
.footer-row .menu-nav {
  width: 100%;
}
.footer-row .menu-nav .menu-item {
  width: 25%;
}
.footer-row .menu-nav .menu-item .nav-link {
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
}
.footer-row .menu-nav .menu-item .nav-link .icon-row {
  height: 60%;
}
.footer-row .menu-nav .menu-item .nav-link .icon-row svg {
  margin: auto;
  height: 4vh;
  width: 100%;
}
.footer-row .menu-nav .menu-item .nav-link .icon-row svg path {
  stroke: #3f3d56;
}
.footer-row .menu-nav .menu-item .nav-link .icon-row svg.active path {
  stroke: white;
}
.footer-row .menu-nav .menu-item .nav-link .text-row {
  height: 40%;
}
.footer-row .menu-nav .menu-item .nav-link .text-row span {
  margin: auto;
  height: fit-content;
  font-size: 1.2em;
  color: #3f3d56;
  letter-spacing: 0.05vw;
}
.footer-row .menu-nav .menu-item .nav-link.active-menu-item {
  pointer-events: none;
  background-color: #6c63ff;
  transition: ease-in 0.3s;
}
.footer-row .menu-nav .menu-item .nav-link.active-menu-item .icon-row svg path {
  stroke: white;
}
.footer-row .menu-nav .menu-item .nav-link.active-menu-item .text-row span {
  color: white;
}

.modal {
  background-color: rgba(255, 255, 255, 0.8);
}

.confirm-door-open-modal .modal-content {
  border-radius: 1vw;
  margin: auto;
  width: 80%;
  border: none;
}
.confirm-door-open-modal .modal-content .modal-header {
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  padding: 0.5vh 2vw;
  background-color: #6c63ff;
}
.confirm-door-open-modal .modal-content .modal-header .modal-title {
  margin: auto 0;
  font-size: 1.5rem;
}
.modal-title{
  font-size: 1.5rem;
}
.confirm-door-open-modal .modal-content .modal-body p {
  font-size: 1rem;
}
.confirm-door-open-modal .modal-content button {
  margin: auto 0;
  text-shadow: none;
  opacity: 1;
}
.confirm-door-open-modal .modal-content button span {
  filter: none;
  box-shadow: none;
  backdrop-filter: none;
  font-size: 2rem;
  color: white;
}

.open-door-modal .modal-content {
  border-radius: 1vw;
  margin: auto;
  width: 80%;
  border: none;
}
.open-door-modal .modal-content .modal-header {
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  padding: 0.5vh 2vw;
  background-color: #6c63ff;
}
.open-door-modal .modal-content .modal-header .modal-title {
  margin: auto 0;
  font-size: 1.2rem;
}
.open-door-modal .modal-content .modal-body {
  padding: 1vh 0;
  margin: 0;
}
.open-door-modal .modal-content .modal-body p {
  font-size: 1rem;
}
.open-door-modal .modal-content .modal-body p span {
  font-weight: bold;
}
.open-door-modal .modal-content .modal-footer {
  padding: 0;
  padding-bottom: 2vh;
  border: none;
}
.open-door-modal .modal-content .modal-footer button {
  background-color: #3f3d56;
  margin: auto;
}
.open-door-modal .modal-content button {
  margin: auto 0;
  text-shadow: none;
  opacity: 1;
}
.open-door-modal .modal-content button span {
  filter: none;
  box-shadow: none;
  backdrop-filter: none;
  font-size: 2rem;
  color: white;
}

html {
  max-width: 100vw;
  max-height: 100vh;
}

#root {
  max-width: 100vw;
  max-height: 100vh;
}

body {
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
}

p {
  font-size: 0.9rem;
  font-weight: 400;
  color: #626262;
}

h4 {
  font-size: 1.32rem;
  font-weight: 500;
  line-height: 1.2;
  color: #2c2c2c;
}

#pin::placeholder {
  color: #787878;
}

.modal-body {
  text-align: center;
}

.card {
  border: none;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.3);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tab-content {
  height: auto;
}
.tab-content .tab-pane {
  height: auto;
}
.tab-content .tab-pane .row {
  margin: 0;
}
.tab-content .tab-pane .submit-office-row {
  width: 100%;
  height: 80vh;
}
.tab-content .tab-pane .submit-office-row .main-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-content .tab-pane .submit-office-row .main-col .main-row {
  width: 80%;
}
.tab-content .tab-pane .submit-office-row .main-col .main-row .office-draw {
  margin: 1vh 0 4vh 0;
}
.tab-content .tab-pane .submit-office-row .main-col .main-row .office-draw svg {
  width: 150px;
  height: auto;
}
.tab-content .tab-pane .submit-office-row .main-col .main-row input {
  margin: auto;
  width: 290px;
  height: 7vh;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: #f6f6f6;
  color: black;
  text-align: center;
}
.tab-content .tab-pane .submit-office-row .main-col .main-row input::placeholder {
  text-align: center;
  font-weight: 300;
}
.tab-content .tab-pane .submit-office-row .main-col .main-row button {
  margin: auto;
  width: 290px;
  height: 7vh;
}
.tab-content .tab-pane .orders-row {
  width: 100%;
  height: 80vh;
}
.tab-content .tab-pane .orders-row .main-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-content .tab-pane .orders-row .main-col .main-row {
  width: 80%;
}
.tab-content .tab-pane .orders-row .main-col .main-row .order-draw {
  margin: 1vh 0 4vh 0;
}
.tab-content .tab-pane .orders-row .main-col .main-row .order-draw svg {
  height: auto;
  width: 150px;
}
.tab-content .tab-pane .orders-row .main-col .main-row input {
  margin: auto;
  width: 290px;
  height: 7vh;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: #f6f6f6;
  color: black;
  text-align: center;
}
.tab-content .tab-pane .orders-row .main-col .main-row input::placeholder {
  text-align: center;
  font-weight: 300;
}
.tab-content .tab-pane .orders-row .main-col .main-row button {
  margin: auto;
  width: 290px;
  height: 7vh;
}
.tab-content .tab-pane .returns-row {
  width: 100%;
  height: 80vh;
}
.tab-content .tab-pane .returns-row .confirm-data-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .refund-draw {
  margin: 4vh 0 4vh 0;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .refund-draw svg {
  height: auto;
  width: 300px;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row input {
  margin: auto;
  width: 290px;
  height: 7vh;
  width: 100%;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: #f6f6f6;
  color: black;
  text-align: center;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row input::placeholder {
  font-weight: 300;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row button {
  margin: auto;
  width: 290px;
  height: 7vh;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row button.change-data-button {
  background-color: #3f3d56;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .title-row p {
  font-size: 1.8em;
  letter-spacing: 0.4vw;
  font-weight: bold;
  color: #474646;
  margin: 0;
  padding: 0;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row.subtitle-row {
  margin-top: 1vh;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row.subtitle-row p {
  margin: 0;
  padding: 0;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container {
  padding: 0;
  width: 290px;
  margin: 4vh auto;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container .row {
  margin: 0 auto 1vh auto;
  width: 100%;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container .row .first-col,
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container .row .last-col {
  padding: 0;
  margin: 0;
  font-size: 1.2em;
  min-width: fit-content;
  max-width: fit-content;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container .row .first-col .left-span,
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container .row .last-col .left-span {
  width: fit-content;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 400;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container .row .first-col .right-span,
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container .row .last-col .right-span {
  font-weight: 200;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container .row .first-col .right-span span,
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container .row .last-col .right-span span {
  margin-left: 0.5vw;
  font-size: 0.7em;
}
.tab-content .tab-pane .returns-row .confirm-data-col .main-row .data-container .row .hr-col {
  width: auto;
  margin: 0;
}
.tab-content .tab-pane .returns-row .order-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-content .tab-pane .returns-row .order-col input {
  margin: auto;
  width: 290px;
  height: 7vh;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: #f6f6f6;
  color: black;
  text-align: center;
}
.tab-content .tab-pane .returns-row .order-col input::placeholder {
  font-weight: 300;
}
.tab-content .tab-pane .returns-row .order-col button {
  margin: auto;
  width: 290px;
  height: 7vh;
}
.tab-content .tab-pane .returns-row .order-col textarea {
  margin: auto;
  width: 290px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: #f6f6f6;
  color: black;
}
.tab-content .tab-pane .returns-row .order-col textarea::placeholder {
  font-weight: 300;
}
.tab-content .tab-pane .returns-row .order-col .select-shop-column {
  margin: auto;
}
.tab-content .tab-pane .returns-row .order-col .select-shop-column .form-group {
  display: flex;
  align-items: center;
  height: 7vh;
  width: 290px;
}
.tab-content .tab-pane .returns-row .order-col .select-shop-column .form-group label {
  margin: auto 0;
  float: right;
  font-size: 1.2em;
}
.tab-content .tab-pane .returns-row .order-col .select-shop-column .form-group select {
  margin-left: auto;
  border: none;
  outline: none;
  box-shadow: none;
  width: fit-content;
  font-weight: 300;
}
.tab-content .tab-pane .returns-row .order-col .select-shop-column .form-group select option {
  font-weight: 700;
}
.tab-content .tab-pane .rental-row {
  width: 100%;
  height: fit-content;
}
.tab-content button {
  outline: none;
  padding: 0.7rem 1.8rem;
  background-color: #6c63ff;
  border: none;
  font-size: 0.9rem;
}
.tab-content button:hover {
  background-color: #3f3d56;
}
.tab-content button:focus {
  background-color: #3f3d56 !important;
  border: none !important;
  box-shadow: none;
}

.text-left {
  margin-left: 10px;
  float: left;
  color: #242424;
}

.logo-img {
  width: 100%;
}

.logo-container {
  background-color: #0782c3;
  padding: 0px !important;
}

.modal-header {
  background-color: #0782c3;
  color: #fff;
}

.text-center {
  text-align: center;
  font-weight: bold;
}

.title {
  font-size: 1.8em;
  letter-spacing: 0.4vw;
  font-weight: bold;
  color: #474646;
}

.text-muted {
  color: #222d3c;
  font-weight: 400;
}

.grecaptcha-badge {
  visibility: hidden;
}

.nav-tabs .nav-link {
  padding: auto 0;
  border-radius: 0;
}
.modal-door-wrapper{
  margin: auto;
  border: 4px solid #0282C3;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-door{
  font-size: 3rem;
  font-weight: 800;
  color: #0282C3;
}

@media only screen and (max-width: 500px) {
  .logo-img {
    padding-top: 7vh;
    padding-bottom: 7vh;
  }
}
@media only screen and (max-width: 410px) {
  .g-recaptcha {
    transform-origin: top left;
    transform: scale(0.77);
  }
}
/*# sourceMappingURL=styles.css.map */
